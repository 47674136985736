<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Metode Pembayaran'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'payment-methods/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/masters/payment-methods/Form.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: "",
        image_name: '',
        image: [],
        description: "",
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get('payment-methods/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/masters/payment-methods/list')
      } else {        
        this.form['_method'] = 'put'
        this.form.image_name = this.form.image
        this.form.image = []
      }
    }

  },

  mounted() {
    if ($this.route.params.id < 4) {
      $this.router.push('/masters/payment-methods/list')
    }
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Metode Pembayaran", route: "" },
      { title: "Daftar Metode Pembayaran", route: "/masters/payment-methods/list" },
      { title: "Ubah" },
    ])
    // Get Data
    this.get()
  },

}

</script>